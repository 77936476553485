'use client'

import {useState} from 'react';
import {Dialog, DialogPanel } from '@headlessui/react';
import {Bars3Icon, ExclamationTriangleIcon, XMarkIcon} from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid'
import Logo from './assets/images/logo.png';
import Playstore from './assets/images/play-store.png';
import Applestore from './assets/images/apple-store.png';
import Mockup1 from './assets/images/mockup1.png';
import Mockup2 from './assets/images/mockup2.png';
import { CalendarDaysIcon, WrenchScrewdriverIcon, BanknotesIcon, ArchiveBoxIcon } from '@heroicons/react/20/solid';
import {CheckCircleIcon} from "@heroicons/react/16/solid";
import Carousel from "./components/Carousel";
import InputMask from 'react-input-mask';

const features = [
  {
    name: 'Agenda',
    description:
        'Organize e acompanhe todos os seus serviços em um só lugar, garantindo controle total dos seus horários.',
    icon: CalendarDaysIcon,
  },
  {
    name: 'Serviços',
    description: 'Adicione e organize seus serviços facilmente, aumentando a visibilidade para seus clientes.',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Financeiro',
    description: 'Acompanhe suas finanças de forma prática, com controle de receitas, despesas e fluxo de caixa.',
    icon: BanknotesIcon,
  },
  {
    name: 'Estoque',
    description: 'Gerencie seus materiais de forma prática, evitando faltas e garantindo a execução dos serviços.',
    icon: ArchiveBoxIcon,
  },
]

const tiers = [
  {
    name: 'Mensal',
    id: 'tier-hobby',
    href: '#',
    priceMonthly: 'R$59,90',
    description: 'Perfeito para quem quer começar sem compromisso, aproveitando todos os recursos essenciais.',
    discount: '',
    features: [],
    featured: false,
    type: 'Mês',
  },
  {
    name: 'Semestral',
    id: 'tier-hobby-semestral',
    href: '#',
    priceMonthly: 'R$49,90',
    description: "Garanta mais economia e resultados por 6 meses com o nosso plano semestral, ideal para quem busca estabilidade com mais vantagens.",
    discount: 'Economia de R$120,00',
    features: [],
    featured: false,
    type: 'Mês',
  },
  {
    name: 'Anual',
    id: 'tier-enterprise',
    href: '#',
    priceMonthly: 'R$39,90',
    description: 'Invista no sucesso a longo prazo com nosso plano anual! Aproveite todos os benefícios exclusivos com o melhor custo-benefício e suporte premium dedicado.',
    discount: 'Economia de R$238,90',
    features: [],
    featured: true,
    type: 'Mês',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const navigation = [
  { name: 'Inicio', href: '#' },
  { name: 'Sobre', href: 'sobre' },
  { name: 'Planos', href: 'planos' },
]

export default function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [step, setStep] = useState(1);
  const [paymentData, setPaymentData] = useState({
    name: null,
    email: null,
    confirmEmail: null,
    cgc: null,
    personType: 'pf',
    payment: {
      name: null,
      cgc: '',
      card_number: null,
      card_date: null,
      cvv: null
    },
    selectedPlan: null,
  })
  const [emailError, setEmailError] = useState(false)

  const checkConfirmEmail = (value) => {
    if (paymentData.email !== value) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }
  }

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const confirmPayment = () => {
    setStep(3);

    setTimeout(() => {
      setStep(4);
    }, 5000)
  }

  const handleScroll = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setMobileMenuOpen(false);
    }
  };

  const handleCGCChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    setPaymentData({ ...paymentData, cgc: value });
  };

  const handlePersonTypeChange = (type) => {
    setPaymentData({ ...paymentData, personType: type, cgc: '' });
  };

  const handlePlanChange = (plan) => {
    setPaymentData({ ...paymentData, selectedPlan: plan });
  }

  return (
      <div className="bg-white">
        <header className="absolute inset-x-0 top-0 z-50">
          <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
            <div className="flex lg:flex-1">
            </div>
            <div className="flex lg:hidden">
              <button
                  type="button"
                  onClick={() => setMobileMenuOpen(true)}
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6"/>
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                  <button
                      key={item.name}
                      onClick={() => handleScroll(item.href)}
                      className="text-sm font-medium leading-6 text-gray-900"
                      style={{fontSize: 16}}
                  >
                    {item.name}
                  </button>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <button className="px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-blue-800">
                Painel
              </button>
            </div>
          </nav>
          <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50"/>
            <DialogPanel
                className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                      alt=""
                      src={Logo}
                      width={150}
                      height={50}
                  />
                </a>
                <button
                    type="button"
                    onClick={() => setMobileMenuOpen(false)}
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6"/>
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                        <button
                            key={item.name}
                            onClick={() => handleScroll(item.href)}
                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            style={{fontSize: 16}}
                        >
                          {item.name}
                        </button>
                    ))}
                  </div>
                  <div className="space-y-2 py-6">
                    <button className="px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-blue-800">
                      <a style={{textDecoration: "none"}} href={'painel.acheiservicos.com.br'} target={'_blank'}>
                        Painel
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </Dialog>
        </header>

        <div className="relative isolate px-6 lg:px-8">
          <div aria-hidden="true"
               className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          >
            <div
                style={{
                  clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ffd100] to-[#284393] opacity-80 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
          </div>

          <div
              className="mx-auto max-w-7xl mt-40 mb-20 flex flex-col lg:flex-row items-center justify-between space-y-12 lg:space-y-0">
            {/* Texto */}
            <div className="max-w-7xl text-center">
              <img className="mb-5" width={300} height={50} src={Logo}/>

              <h1 className="text-balance text-3xl  tracking-tight text-gray-900 sm:text-4xl">
                Aumente suas vendas com o melhor aplicativo para prestadores de serviços
              </h1>

              <p className="mx-auto mt-6 text-lg leading-8 text-gray-600">
                Tenha a oportunidade de expandir sua clientela, promovendo seus serviços para uma audiência ampla e
                qualificada, tudo em um só lugar. Aproveite nossa plataforma para conectar demandas e soluções de
                maneira
                inteligente e eficiente, elevando o nível do seu negócio e garantindo a satisfação dos seus clientes.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                    href="https://play.google.com/store"
                    className="flex items-center justify-center rounded-lg bg-blue-900 text-white px-6 py-3 shadow-md hover:bg-blue-800 transition"
                >
                  <img
                      src={Playstore}
                      alt="Google Play"
                      className="h-6 w-6 mr-3"
                  />
                  <div className="text-left">
                    <p className="text-xs">Baixar no</p>
                    <p className="font-semibold">Google Play</p>
                  </div>
                </a>

                {/* Botão App Store */}
                <a
                    href="https://www.apple.com/app-store/"
                    className="flex items-center justify-center rounded-lg bg-blue-900 text-white px-6 py-3 shadow-md hover:bg-blue-800 transition"
                >
                  <img
                      src={Applestore}
                      alt="App Store"
                      className="h-6 w-6 mr-3"
                  />
                  <div className="text-left">
                    <p className="text-xs">Baixar na</p>
                    <p className="font-semibold">App Store</p>
                  </div>
                </a>
              </div>
            </div>

            {/* Imagem do celular */}
            <div className="relative">
              <img width={2000} height={500} src={Mockup1}/>
            </div>
          </div>


          <div
              aria-hidden="true"
              className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          >
            <div
                style={{
                  clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ffd100] to-[#284393] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>

          <div id="sobre">
            <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <h2 className="text-base font-semibold leading-7 text-blue-900">Sobre</h2>
                <p className="mt-2 text-pretty text-4xl tracking-tight text-gray-900 sm:text-4xl lg:text-balance">
                  Tudo que você precisa, na palma da sua mão
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Nosso aplicativo foi desenvolvido para facilitar a vida dos prestadores de serviços, com ferramentas
                  que permitem gerenciar sua agenda, controlar seu estoque e acompanhar suas receitas. E o melhor: seus
                  clientes podem agendar serviços diretamente no app.
                </p>
              </div>

              <Carousel>
                <div id="item1" className="rounded-full lg:row-span-2 mt-10 h-full w-full object-cover">
                  <div className="relative flex h-full flex-row overflow-hidden">
                    <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                      <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                        O Aplicativo
                      </p>
                      <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                        Tenha a flexibilidade de gerenciar suas demandas a qualquer momento, diretamente do seu
                        smartphone. Com uma interface totalmente otimizada para dispositivos móveis, você pode
                        acompanhar
                        solicitações no seu aplicativo de onde estiver, garantindo agilidade e conveniência.
                      </p>
                    </div>

                    <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                      <div className="absolute inset-x-10 bottom-0 top-20 overflow-hidden">
                        <img src={Mockup2}/>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="item2" className="rounded-full lg:row-span-2 mt-10 h-full w-full object-cover">
                  <div className="relative flex h-full flex-row overflow-hidden">
                    <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                      <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                        Painel Gerencial
                      </p>
                      <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                        Tenha controle total do seu negócio em um só lugar. Com nosso painel gerencial, você pode
                        cadastrar serviços, gerenciar categorias, acompanhar uma agenda integrada e organizar todos
                        os
                        seus compromissos de forma prática e eficiente. Tudo pensado para facilitar a gestão do seu
                        dia
                        a
                        dia.
                      </p>
                    </div>

                    <div className="relative pr-5">
                      <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-9">
                              <dt className="inline font-semibold text-gray-900">
                                <feature.icon aria-hidden="true"
                                              className="absolute left-1 top-1 h-5 w-5 text-yellow-400"/>
                                {feature.name}
                              </dt>
                              {' '}
                              <dd className="text-sm/6 text-gray-600 max-lg:text-center">{feature.description}</dd>
                            </div>
                        ))}
                      </dl>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>

          <div className="relative isolate px-6 py-24 sm:py-32 lg:px-8">
            <div aria-hidden="true"
                 className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
              <div
                  style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                  className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ffd100] to-[#284393] opacity-80"
              />
            </div>
            <div id="planos" className="mx-auto max-w-4xl text-center">
              <h2 className="text-base font-semibold leading-7 text-blue-900">Planos</h2>
              <p className="mt-2 text-pretty text-4xl tracking-tight text-gray-900 sm:text-4xl lg:text-balance">
                Selecione o plano ideal
              </p>
            </div>
            <p className="mx-auto mt-6 text-lg leading-8 text-gray-600 max-w-2xl text-center">
              Escolha um plano acessível que esteja repleto dos melhores recursos para envolver seu público, criar
              fidelidade do cliente e impulsionar seus serviços.
            </p>
            <div
                className="mx-auto mt-16 grid max-w-7xl grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-7xl lg:grid-cols-3">
              {tiers.map((tier, tierIdx) => (
                  <div
                      key={tier.id}
                      className={classNames(
                          tier.featured ? 'relative bg-blue-900 shadow-2xl' : 'bg-white/60 sm:mx-8 lg:mx-0',
                          tier.featured
                              ? ''
                              : tierIdx === 0 || tierIdx === 1
                                  ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
                                  : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl',
                          'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10',
                      )}
                  >
                    <h3
                        id={tier.id}
                        className={classNames(
                            tier.featured ? 'text-yellow-300' : 'text-blue-900',
                            'text-base font-semibold leading-7',
                        )}
                    >
                      {tier.name}
                    </h3>
                    <p className="mt-4 flex items-baseline gap-x-2">
                        <span
                            className={classNames(
                                tier.featured ? 'text-white' : tierIdx === 1 ? 'text-gray-900 sm:text-4xl' : 'text-gray-900 sm:text-2xl',
                                'text-5xl font-semibold tracking-tight',
                            )}
                        >
                          {tier.priceMonthly}
                        </span>

                      <span
                          className={classNames(tier.featured ? 'text-gray-400' : 'text-gray-500', 'text-base')}>/ {tier.type}</span>
                    </p>
                    <p>
                      <span
                          className={classNames(tier.featured ? 'text-white' : 'text-gray-500', 'text-base')}>{tier.discount}</span>
                    </p>
                    <p
                        className={classNames(tier.featured ? 'text-white' : 'text-gray-600', 'mt-6 text-base leading-7')}>
                      {tier.description}
                    </p>
                    <ul
                        role="list"
                        className={classNames(
                            tier.featured ? 'text-gray-300' : 'text-gray-600',
                            'mt-8 space-y-3 text-sm leading-6 sm:mt-10',
                        )}
                    >
                      {tier.features.map((feature) => (
                          <li key={feature} className="flex gap-x-3">
                            <CheckIcon
                                aria-hidden="true"
                                className={classNames(tier.featured ? 'text-yellow-300' : 'text-blue-800', 'h-6 w-5 flex-none')}
                            />
                            {feature}
                          </li>
                      ))}
                    </ul>
                    <a
                        onClick={() => {
                          handlePlanChange(tier);
                          setOpenModal(true);
                        }}
                        href={tier.href}
                        aria-describedby={tier.id}
                        className={classNames(
                            tier.featured
                                ? 'bg-yellow-400 text-white shadow-sm hover:bg-yellow-200 focus-visible:outline-indigo-500'
                                : 'bg-blue-900 text-white ring-1 ring-inset ring-blue-900 hover:ring-blue-600 hover:bg-blue-800 focus-visible:outline-indigo-600',
                            'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10',
                        )}
                    >
                      Selecionar Plano
                    </a>
                  </div>
              ))}
            </div>
          </div>
        </div>

        <Dialog open={openModal} onClose={setOpenModal} className="relative z-10">
          <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"/>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            (
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 shadow-lg transition-all">
              <div className="flex items-center justify-center mb-4">
                <Dialog.Title className="ml-3 text-lg font-medium text-gray-900">
                  {step === 1 ? (
                      <span>Dados Pessoais</span>
                  ) : step === 2 ? (
                      <span>Dados de Pagamento</span>
                  ) : (
                      <span>Revisão da Solicitação</span>
                  )}
                </Dialog.Title>
              </div>

              {/* Steps navigation */}
              <ol className="flex items-center justify-center space-x-4 text-sm font-medium text-gray-500 sm:text-base">
                <li className={`flex items-center ${step >= 1 ? 'text-green-600' : 'text-gray-400'}`}>
                    <span className="flex items-center">
                        <span className="me-2" style={{ fontSize: 10 }}></span>
                        <p style={{ fontSize: 10 }}>Dados Pessoais</p>
                    </span>
                </li>
                <li className={`flex items-center ${step >= 2 ? 'text-green-600' : 'text-gray-400'}`}>
                    <span className="flex items-center">
                        <span className="me-2" style={{ fontSize: 10 }}>-</span>
                        <p style={{ fontSize: 10 }}>Pagamento</p>
                    </span>
                </li>
                <li className={`flex items-center ${step >= 3 ? 'text-green-600' : 'text-gray-400'}`}>
                  <span className="me-2" style={{ fontSize: 10 }}>-</span>
                  <p style={{ fontSize: 10 }}>Confirmação</p>
                </li>
              </ol>

              {/* Step 1: Informações do usuário */}
              {step === 1 && (
                  <div>
                    <form className="mt-4 space-y-4">
                      <div className="flex items-center space-x-4 mb-4">
                        <label className="flex items-center">
                          <input
                              type="radio"
                              name="personType"
                              value="pf"
                              checked={paymentData.personType === 'pf'}
                              onChange={() => handlePersonTypeChange('pf')}
                              className="mr-2"
                          />
                          Pessoa Física
                        </label>
                        <label className="flex items-center">
                          <input
                              type="radio"
                              name="personType"
                              value="pj"
                              checked={paymentData.personType === 'pj'}
                              onChange={() => handlePersonTypeChange('pj')}
                              className="mr-2"
                          />
                          Pessoa Jurídica
                        </label>
                      </div>

                      <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          {`${paymentData.personType === 'pf' ? 'Nome completo' : 'Razão Social'}`}
                        </label>
                        <input
                            value={paymentData.name}
                            type="text"
                            id="name"
                            onChange={(e) => setPaymentData({ ...paymentData, name: e.target.value })}
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                            placeholder={paymentData.personType === 'pf' ? 'Seu nome' : 'Razão social da empresa'}
                        />
                      </div>

                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">E-mail</label>
                        <input
                            value={paymentData.email}
                            onChange={(e) => setPaymentData({ ...paymentData, email: e.target.value })}
                            type="email"
                            id="email"
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                            placeholder="seu@email.com"
                        />
                      </div>

                      <div>
                        <label htmlFor="confirmEmail" className="block text-sm font-medium text-gray-700">Confirme seu e-mail</label>
                        <input
                            value={paymentData.confirmEmail}
                            onChange={(e) => {
                              setPaymentData({ ...paymentData, confirmEmail: e.target.value });
                              checkConfirmEmail(e.target.value);
                            }}
                            type="email"
                            id="confirmEmail"
                            className={`block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm ${emailError ? 'border-red-500 ring-red-500 focus:ring-red-500' : ''}`}
                        />
                        {emailError && <p className="mt-2 text-sm text-red-600">Os e-mails não coincidem</p>}
                      </div>

                      <div>
                        <label htmlFor="cpf" className="block text-sm font-medium text-gray-700">{
                          `${paymentData.personType === 'pf' ? 'CPF' : 'CNPJ'}`
                        }</label>
                        <InputMask
                            mask={paymentData.personType === 'pf' ? '999.999.999-99' : '99.999.999/9999-99'}
                            value={paymentData.cgc}
                            onChange={handleCGCChange}
                            type="text"
                            id="cpf"
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                            placeholder={paymentData.personType === 'pf' ? "000.000.000-00" : "00.000.000/0000-00"}
                        />
                      </div>
                    </form>

                    <div className="mt-6 flex justify-end space-x-4">
                      <button
                          type="button"
                          onClick={() => setOpenModal(false)}
                          className="rounded-md bg-gray-200 px-4 py-2 text-sm text-gray-700 hover:bg-gray-300"
                      >
                        Cancelar
                      </button>
                      <button
                          disabled={emailError}
                          type="button"
                          onClick={nextStep}
                          className="rounded-md bg-blue-900 px-4 py-2 text-sm text-white hover:bg-blue-800"
                      >
                        Próximo
                      </button>
                    </div>
                  </div>
              )}

              {/* Step 2: Informações do cartão */}
              {step === 2 && (
                  <div>
                    <form className="mt-4 space-y-4">
                      <div>
                        <label htmlFor="cardholderName" className="block text-sm font-medium text-gray-700">Nome do titular</label>
                        <input
                            value={paymentData.payment.name}
                            onChange={(e) => setPaymentData({ ...paymentData, payment: { ...paymentData.payment, name: e.target.value } })}
                            type="text"
                            id="cardholderName"
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                            placeholder="Nome igual ao do cartão"
                        />
                      </div>

                      <div>
                        <label htmlFor="cpfCardholder" className="block text-sm font-medium text-gray-700">CPF do titular</label>
                        <InputMask
                            mask="999.999.999-99"
                            value={paymentData.payment.cgc}
                            onChange={(e) => setPaymentData({ ...paymentData, payment: { ...paymentData.payment, cgc: e.target.value } })}
                            type="text"
                            id="cpfCardholder"
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                            placeholder="000.000.000-00"
                        />
                      </div>

                      <div>
                        <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700">Número do Cartão</label>
                        <InputMask
                            mask="9999 9999 9999 9999"
                            value={paymentData.payment.card_number}
                            onChange={(e) => setPaymentData({ ...paymentData, payment: { ...paymentData.payment, card_number: e.target.value } })}
                            type="text"
                            id="cardNumber"
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                            placeholder="0000 0000 0000 0000"
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700">Data de Expiração</label>
                          <InputMask
                              mask="99/99"
                              value={paymentData.payment.card_date}
                              onChange={(e) => setPaymentData({ ...paymentData, payment: { ...paymentData.payment, card_date: e.target.value } })}
                              type="text"
                              id="expiryDate"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                              placeholder="MM/AA"
                          />
                        </div>
                        <div>
                          <label htmlFor="cvv" className="block text-sm font-medium text-gray-700">CVV</label>
                          <InputMask
                              mask="999"
                              value={paymentData.payment.cvv}
                              onChange={(e) => setPaymentData({ ...paymentData, payment: { ...paymentData.payment, cvv: e.target.value } })}
                              type="text"
                              id="cvv"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                              placeholder="***"
                          />
                        </div>
                      </div>
                    </form>

                    <div className="mt-6 flex justify-between space-x-4">
                      <button
                          type="button"
                          onClick={prevStep}
                          className="rounded-md bg-gray-200 px-4 py-2 text-sm text-gray-700 hover:bg-gray-300"
                      >
                        Voltar
                      </button>
                      <button
                          type="button"
                          onClick={() => confirmPayment()}
                          className="rounded-md bg-blue-900 px-4 py-2 text-sm text-white hover:bg-blue-800"
                      >
                        Confirmar Pagamento
                      </button>
                    </div>
                  </div>
              )}

              {/* Step 3: Revisão da solicitação */}
              {step === 3 && (
                  <div className="flex flex-col items-center justify-center">
                    <div className="loader border-t-blue-900 border-4 rounded-full w-16 h-16 animate-spin mb-4"></div>
                    <p className="text-lg font-semibold">Processando pagamento...</p>
                  </div>
              )}

              {/* Step 4: Confirmação */}
              {step === 4 && (
                  <div className="flex flex-col items-center justify-center">
                    <div className="mx-auto mt-5 mb-5 flex h-32 w-32 flex-shrink-0 items-center justify-center rounded-full bg-green-200 sm:mx-0 sm:h-24 sm:w-24">
                      <CheckCircleIcon aria-hidden="true" className="h-16 w-16 text-green-600"/>
                    </div>
                    <h2 className="text-2xl font-bold">Pagamento realizado com sucesso!</h2>
                    <p className="mt-4 text-center">As instruções e dados de acesso foram enviadas para o seu e-mail.</p>
                    <button
                        className="mt-6 px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-blue-800"
                        onClick={() => {
                          setStep(1);
                          setOpenModal(false);
                        }}
                    >
                      Fechar
                    </button>
                  </div>
              )}
            </Dialog.Panel>
          </div>
        </Dialog>

        <footer
            className="bg-zinc-50 text-center text-surface/75 dark:bg-blue-900/100 dark:text-white/75 lg:text-left">
          <div className="mx-6 py-10 text-center text-center">
            <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
              <div></div>
              <div>
                <h6
                    className="mb-4 flex justify-center font-semibold uppercase md:justify-center">
                  Contato
                </h6>
                <p className="mb-4 flex items-center justify-center md:justify-start">
                  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor">
                      <path
                          d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z"/>
                      <path
                          d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"/>
                    </svg>
                  </span>
                  Concórdia, SC
                </p>
                <p className="mb-4 flex items-center justify-center md:justify-start">
                  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor">
                      <path
                          d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"/>
                      <path
                          d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"/>
                    </svg>
                  </span>
                  contato.acheiservicos@gmail.com
                </p>
                <p className="mb-4 flex items-center justify-center md:justify-start">
                  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor">
                      <path
                          fill-rule="evenodd"
                          d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                          clip-rule="evenodd"/>
                    </svg>
                  </span>
                  (49) 9 9117-0701
                </p>
              </div>
              <div>
                <h6
                    className="mb-4 flex justify-center font-semibold uppercase md:justify-center">
                  Aplicativo
                </h6>
                <a href="#">Termos de uso e privacidade</a>

                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a
                      href="https://play.google.com/store"
                      className="flex items-center justify-center rounded-lg bg-yellow-400 text-white px-6 py-3 shadow-md hover:bg-blue-800 transition"
                  >
                    <img
                        src={Playstore}
                        alt="Google Play"
                        className="h-6 w-6 mr-3"
                    />
                    <div className="text-left">
                      <p className="text-xs">Baixar no</p>
                      <p className="font-semibold">Google Play</p>
                    </div>
                  </a>

                  {/* Botão App Store */}
                  <a
                      href="https://www.apple.com/app-store/"
                      className="flex items-center justify-center rounded-lg bg-yellow-400 text-white px-6 py-3 shadow-md hover:bg-blue-800 transition"
                  >
                    <img
                        src={Applestore}
                        alt="App Store"
                        className="h-6 w-6 mr-3"
                    />
                    <div className="text-left">
                      <p className="text-xs">Baixar na</p>
                      <p className="font-semibold">App Store</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-blue-900 p-6 text-center">
            <span>© 2024 Copyright:</span>
            <a className="font-semibold" href="https://tw-elements.com/"
            >Achei Serviços</a
            >
          </div>
        </footer>
      </div>
  )
}
